<template>
  <b-card>
    {{ avatar }}
    <avatar-img
      :textNoImg="fullname"
      :avatar="avatar"
      @avatarFile="(val) => (profileFile = val)"
    />
    <!-- form -->
    <validation-observer ref="generalProfileForm" #default="{ invalid }">
      <b-form class="mt-2" @submit.prevent="saveChange">
        <b-row>
          <b-col sm="6">
            <b-form-group :label="labelUsername" label-for="account-username">
              <validation-provider
                #default="{ errors }"
                mode="eager"
                name="User"
                vid="username"
                rules="required|min:4|max:50"
              >
                <b-form-input
                  v-model="username"
                  :placeholder="placeholderUsername"
                  name="username"
                  :state="errors.length > 0 ? false : null"
                  :disabled="true"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group :label="labelfullname" label-for="account-name">
              <validation-provider
                #default="{ errors }"
                mode="eager"
                name="fullname"
                vid="fullname"
                rules="required|min:6|max:100"
              >
                <b-form-input
                  v-model="fullname"
                  name="name"
                  :placeholder="labelfullname"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group :label="labelEmail" label-for="account-e-mail">
              <b-form-input
                v-model="email"
                name="email"
                :placeholder="labelEmail"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              :label="labelPhoneNumber"
              label-for="account-phone-number"
            >
              <b-form-input
                v-model="phoneNumber"
                name="phoneNumber"
                :placeholder="placeholderPhoneNumber"
              />
            </b-form-group>
          </b-col>
          <!-- alert -->
          <b-col cols="12" class="mt-75">
            <b-alert :show="changedForm" variant="danger" class="mb-50">
              <div class="alert-body">
                {{ $t("Há alterações não salvas.") }}
              </div>
            </b-alert>
            <b-alert :show="!sendEmail" variant="warning" class="mb-50 p-1">
              {{
                $t(`Para sua segurança, os dados da sua conta só poderão ser modificados mediante a confirmação de um
                            código enviado para seu e-mail.`)
              }}
              <div class="mt-1">
                <b-button size="sm" variant="danger" @click="sendCode">{{
                  $t(`Clique aqui para receber o código no
                                  e-mail`)
                }}</b-button>
              </div>
            </b-alert>
            <alert
              :text="textAlert"
              :color="colorAlert"
              :showAlert="showAlert"
              @changeVariableAlert="(val) => (showAlert = val)"
              class="mb-1"
            />
          </b-col>
          <!--/ alert -->

          <b-col cols="12">
            <div class="d-flex mb-2 mb-sm-0 my-sm-2">
              <b-form-checkbox v-model="enable2fa" class="custom-control-primary" name="check-button" switch />
              <b-card-text :class="enable2fa ? 'text-success' : 'text-danger'">{{ enable2fa ? $t(`Autenticação de dois fatores habilitada`) : $t('Autenticação de dois fatores desabilitada') }}</b-card-text>
            </div>

            <!-- <div class="d-flex mb-2 mb-sm-0 my-sm-2">
              <b-form-checkbox v-model="enable2fa_app" class="custom-control-primary" name="check-button" switch />
              <b-card-text :class="enable2fa_app ? 'text-success' : 'text-danger'">{{ enable2fa_app ? $t(`Autenticação de dois fatores por app habilitada`) : $t('Autenticação de dois fatores por app desabilitada') }}</b-card-text>
            </div>
            <b-button
              v-if="enable2fa_app && !config2faApp && !configured2FA_App"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-primary"
              class="mb-1"
              @click="setup2FA_app"
              :disabled="loadingGenerateQrcode"
            >
              {{ !loadingGenerateQrcode ? $t("Configurar 2FA por APP") : $t('Aguarde...') }}
            </b-button>

            <div v-if="enable2fa_app && config2faApp" class="my-2">
                <p>{{ $t('Escaneie este código com seu aplicativo autenticador')}}:</p>
                <img :src="qrcode" fluid alt="QR Code">                                            
            </div> -->

            <b-form-group :label="$t('Código de segurança')">
              <validation-provider
                #default="{ errors }"
                mode="eager"
                name="securityCode"
                vid="securityCode"
                rules="min:10|max:10"
              >
                <b-form-input
                  v-model="securityCode"
                  name="code"
                  :placeholder="$t('Digite o código de segurança')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-button
              type="submit"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              :disabled="invalid || loading"
            >
              <div v-if="!loading">
                {{ $t("Salvar alterações") }}
              </div>
              <div v-else>
                <b-spinner small type="grow"></b-spinner>
                {{ $t("Carregando...") }}
              </div>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              type="reset"
              class="mt-2"
              @click.prevent="resetForm"
            >
              {{ $t("Redefinir") }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import Ripple from "vue-ripple-directive";
import { mapGetters, mapActions } from "vuex";

import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { required, email, min } from "@/libs/validations";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { BFormCheckbox } from "bootstrap-vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      avatar: null,
      username: null,
      fullname: null,
      email: null,
      phoneNumber: null,
      profileFile: null,
      loading: false,
      urlImage: null,
      enable2fa: false,
      enable2fa_app: false,
      config2faApp: false,
      qrcode: null,
      loadingGenerateQrcode: false,
      configured2FA_App: false,

      labelUsername: this.$i18n.t("Usuário"),
      placeholderUsername: this.$i18n.t("Nome do usuário"),
      labelfullname: this.$i18n.t("Nome completo"),
      labelEmail: this.$i18n.t("E-mail"),
      labelPhoneNumber: this.$i18n.t("Número do celular"),
      placeholderPhoneNumber: this.$i18n.t("Digite seu número para contatos"),

      sendEmail: false,
      colorAlert: "warning",
      showAlert: false,
      textAlert: "",
      securityCode: "",

      //validation
      required,
      email,
      min,
    };
  },
  computed: {
    ...mapGetters(["returnUser", "returnLocale"]),
    changedForm() {
      return (
        ((this.username != this.returnUser.username && this.username != null) ||
          (this.fullname != this.returnUser.fullname &&
            this.fullname != null) ||
          (this.phoneNumber != this.returnUser.mobileNumber &&
            this.phoneNumber != null) ||
          this.profileFile != null) &&
        this.securityCode.length == 10
      );
    },
  },
  mounted() {
    this.loadingDataProfile();
  },
  watch: {
    returnLocale: function (oldVal, newVal) {
      localize(oldVal);
      this.labelUsername = this.$i18n.t("Usuário");
      this.placeholderUsername = this.$i18n.t("Nome do usuário");
      this.labelfullname = this.$i18n.t("Nome completo");
      this.labelEmail = this.$i18n.t("E-mail");
      this.labelPhoneNumber = this.$i18n.t("Número de telefone");
      this.placeholderPhoneNumber = this.$i18n.t(
        "Digite seu número para contatos"
      );
    },
    returnUser: function (oldVal, newVal) {
      this.loadingDataProfile();
    },
  },
  methods: {
    ...mapActions(["userEditProfile", "recoverUserData", "confirmEmail"]),
    async setup2FA_app() {
      this.loadingGenerateQrcode = true;
      this.$store.dispatch("setup2FA").then((resp) => {
            this.qrcode = resp.qr_code
            this.config2faApp = true;
            this.loadingGenerateQrcode = false;
        })
    },
    sendCode() {
      const data = {
        clientEmail: this.returnUser.email,
        clientName: this.returnUser.fullname,
      };
      this.$store.dispatch("sendSecurityCode", data).then((resp) => {
        this.sendEmail = true;
        this.colorAlert = "success";
        this.showAlert = true;
        this.textAlert = resp.message;
      });
    },
    loadingDataProfile() {
      this.username = this.returnUser.username;
      this.fullname = this.returnUser.fullname;
      this.email = this.returnUser.email;
      this.phoneNumber = this.returnUser.mobileNumber;
      this.avatar = this.returnUser?.avatar
        ? `${process.env.VUE_APP_API_URL}/avatar/${this.returnUser.avatar}`
        : null;
      this.enable2fa = this.returnUser.authentication2fa;
      this.enable2fa_app = this.returnUser.authentication2fa_app;
      this.configured2FA_App = this.returnUser.configured2FA_App;
    },
    resetForm() {
      this.username = this.returnUser.username;
      this.fullname = this.returnUser.fullname;
      this.email = this.returnUser.email;
      this.phoneNumber = this.returnUser.mobileNumber;
    },
    resetImage() {
      this.profileFile = null;
    },
    saveChange() {
      this.$refs.generalProfileForm.validate().then((success) => {
        if (success) {
          this.loading = true;
          const data = {
            fullname: this.fullname,
            username: this.username,
            mobileNumber: this.phoneNumber,
            avatar: this.profileFile,
            securityCode: this.securityCode,
            authentication2fa: this.enable2fa,
            authentication2fa_app: this.enable2fa_app
          };

          if (this.phoneNumber == this.returnUser.mobileNumber) {
            delete data.mobileNumber;
          }

          if (this.username == this.returnUser.username) {
            delete data.username;
          }

          if (this.fullname == this.returnUser.fullname) {
            delete data.fullname;
          }

          if (this.profileFile == null) {
            delete data.avatar;
          }

          this.userEditProfile(data)
            .then((response) => {
              this.recoverUserData();
              this.resetImage();
              this.loading = false;
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  icon: "CoffeeIcon",
                  variant: "success",
                  title: this.$i18n.t("Dados alterados!"),
                  text: this.$i18n.t(
                    "As informações do seu perfil foram atualizadas."
                  ),
                },
              });
            })
            .catch((error) => {
              this.$refs.generalProfileForm.setErrors(error.errors);
              this.loading = false;
            });
        }
      });
    },
    sendConfirmationEmail() {
      this.confirmEmail()
        .then((response) => {
          this.emailSent = true;
        })
        .catch((error) => {});
    },
  },
};
</script>
