<template>
  <b-card>
    <validation-observer ref="walletProfileForm" #default="{ invalid }">
      <b-form class="mt-2" @submit.prevent="saveChange">
        <b-row>
          <!-- alert -->
          <b-col cols="12">
            <b-card :show="!sendEmail" style="background-color: rgba(3, 228, 255, 0.1);">
              {{
                $t(`Para sua segurança, a frase de recuperação secreta só será revelada mediante a confirmação de um código enviado para seu e-mail. Com esta frase você poderá carregar e usar a sua wallet blockchain na Metamask, por exemplo.`)
              }}
              <div class="d-flex justify-content-end mt-1">
                <b-button size="sm" variant="outline-primary" @click="sendCode">{{
                  $t(`Enviar o código por e-mail`)
                }}</b-button>
              </div>
            </b-card>
            <alert :text="textAlert" :color="colorAlert" :showAlert="showAlert"
              @changeVariableAlert="(val) => (showAlert = val)" class="mb-1" />
          </b-col>
          <!--/ alert -->
          <b-col cols="12">
            <b-form-group :label="$t('Código de segurança')">
              <validation-provider #default="{ errors }" mode="eager" name="securityCode" vid="securityCode">
                <b-form-input v-model="securityCode" name="code" :placeholder="$t('Digite o código de segurança')"
                  :state="errors.length > 0 ? false : null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <!-- buttons -->
          <b-col cols="12">
            <div class="d-flex justify-content-end">
              <!-- <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="none" type="reset"
                class="mt-2 mr-1" @click.prevent="resetForm">
                {{ $t("Redefinir") }}
              </b-button> -->
              <b-button type="submit" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-2"
                :disabled="invalid || loading">
                <div v-if="!loading">
                  {{ $t("Revelar frase de recuperação secreta") }}
                </div>
                <div v-else>
                  <b-spinner small type="grow"></b-spinner>
                  {{ $t("Carregando...") }}
                </div>
              </b-button>
            </div>
          </b-col>
          <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { min, max } from "@/libs/validations";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      bitcoinWallet: "",
      min,
      max,
      showAlert: false,
      colorAlert: "warning",
      textAlert: "",
      loading: false,
      sendEmail: false,
      securityCode: "",
    };
  },
  computed: mapGetters(["returnUser"]),
  methods: {
    ...mapActions(["userEditProfile", "recoverUserData"]),
    sendCode() {
      const data = {
        clientEmail: this.returnUser.email,
        clientName: this.returnUser.fullname,
      };
      this.$store.dispatch("sendSecurityCode", data).then((resp) => {
        this.sendEmail = true;
        this.colorAlert = "success";
        this.showAlert = true;
        this.textAlert = resp.message;
      });
    },
    saveChange() {
      this.$refs.walletProfileForm.validate().then((success) => {
        if (success) {
          this.loading = true;
          const data = {
            securityCode: this.securityCode
          };
          this.$store.dispatch("revealSecretPhrase", data).then((resp) => {
              this.loading = false;
              this.showAlert = true;
              this.colorAlert = "success";
              this.textAlert = this.$i18n.t(
                "¡Frase secreta enviada al correo electrónico!"
              );
              this.securityCode = ""
            })
            .catch((error) => {
              this.$refs.walletProfileForm.setErrors(error.errors);
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
